import React from 'react'
import {graphql,StaticQuery} from "gatsby"
import Layout from '../components/layout'
import Container from '../components/container'
import InnerHTML from 'html-react-parser'
import Metatags from '../components/Metatags'

const TermsOfService = () => (
    <Layout>
<Metatags
            title="Optinly | Terms and Conditions"
            description = "Read our website's legal agreement covering usage, privacy, intellectual property, and more. Stay informed before using our services."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/terms-of-service/"
        />
        <div className="breadcrumb_top">
          <div className="container">
              <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                      <h2>Terms of Service</h2>
                      <p>Please fill-in and submit the form with your request.</p>
                  </div>
              </div>
          </div>
        </div>

        <Container type="s">
            <div className="legal-info">
            <StaticQuery
            query={TosPolicyQuery}
            render={data => {
            return (
                <Container type="s">
                   {InnerHTML(data.wpPage.content)}
                </Container>
            )
            }}
        />
            </div>
        </Container>
    </Layout>
);

const TosPolicyQuery = graphql`
query TosPolicyQuery {
  wpPage(slug: {eq: "terms-of-service"}) {
    title
    uri
    content
  }
}
`

export default TermsOfService
